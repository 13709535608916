import 'bootstrap'
import $ from 'jquery'
import Keen from 'keen-tracking'

Keen.ready(() => {
  const client = new Keen({
    host: 'keen.findeck.de',
    projectId: process.env.KEEN_PROJECT_ID,
    writeKey: process.env.KEEN_WRITE_KEY
  })
  if (window.location.search.includes('no-tracking') === false) {
    client.initAutoTracking()
  }
})

const youtubeURL =
  'https://www.youtube.com/embed/hDyWTq27zks?rel=0&showinfo=0&modestbranding=1&autoplay=1'

$('#videoModal').on('shown.bs.modal', () => {
  $('#videoFrame').attr('src', youtubeURL)
})

$('#videoModal').on('hide.bs.modal', () => {
  $('#videoFrame').attr('src', '')
})
